/* Apply styles to all img tags */
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 8px 0;
}

/* Apply specific styles to img tags with a certain class */
img.special {
  border-color: #ff5733;
}

/* Apply styles to img tags within a specific container */
.container img {
  max-width: 80%;
  /* Limit image width within the container */
}

/* Apply styles to img tags with a specific ID */
#featured-image {
  width: 100%;
  /* Make sure the image fills its container */
  height: auto;
}

.footer_icons {
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
}

.disclaimer {
  font-size: 14px;
  color: azure;

}

.border-t {
  border-top-width: 1px;
}

.flex {
  display: row;
}

.footer_icons_container {
  margin: 5px 0;
  row-gap: 1.5rem;
  /* column-gap: 1.5rem; */

}
.carousel__next,.carousel__prev {
  align-items: center;
  background: var(--vc-nav-background);
  border: 0;
  border-radius: var(--vc-nav-border-radius);
  box-sizing: content-box;
  color: var(--vc-nav-color);
  cursor: pointer;
  display: flex;
  font-size: var(--vc-nav-height);
  height: var(--vc-nav-height);
  justify-content: center;
  margin: 0 10px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: var(--vc-nav-width)
}

.carousel__next:hover,.carousel__prev:hover {
  color: var(--vc-nav-color-hover)
}

.carousel__next--disabled,.carousel__prev--disabled {
  cursor: not-allowed;
  opacity: .5
}

.carousel__prev {
  left: 0
}

.carousel__next {
  right: 0
}

.carousel--rtl .carousel__prev {
  left: auto;
  right: 0
}

.carousel--rtl .carousel__next {
  left: 0;
  right: auto
}

.carousel {
  box-sizing: border-box;
  overscroll-behavior: none;
  position: relative;
  text-align: center;
  touch-action: pan-y
}

.carousel.is-dragging {
  touch-action: none
}

.carousel * {
  box-sizing: border-box
}

.carousel__track {
  display: flex;
  padding: 0!important;
  position: relative
}

.carousel__viewport {
  overflow: hidden
}

.carousel__sr-only {
  clip: rect(0,0,0,0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.carousel__icon {
  fill: currentColor;
  height: var(--vc-icn-width);
  width: var(--vc-icn-width)
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  line-height: 0;
  list-style: none;
  margin: 10px 0 0
}

.carousel__pagination-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: var(--vc-pgn-margin)
}

.carousel__pagination-button:after {
  background-color: var(--vc-pgn-background-color);
  border-radius: var(--vc-pgn-border-radius);
  content: "";
  display: block;
  height: var(--vc-pgn-height);
  width: var(--vc-pgn-width)
}

.carousel__pagination-button--active:after,.carousel__pagination-button:hover:after {
  background-color: var(--vc-pgn-active-color)
}

.carousel__slide {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0;
  position: relative;
  scroll-snap-stop:auto;transform: translateZ(0)
}

/* ///////////////////////////////////////////////////// */
.disclaimer {
  font-size: 14px;
  color: #ffffff; /* Set your desired text color */
  line-height: 1.5;
  margin-bottom: 20px; /* Set your desired margin */
}

.disclaimer span {
  display: block;
  margin-top: 10px; /* Set your desired margin */
}

.disclaimer a {
  color: #007BFF; /* Set your desired link color */
  text-decoration: none;
  border-bottom: 1px solid #007BFF; /* Add an underline effect */
}

.disclaimer a:hover {
  color: #0056b3; /* Set your desired link color on hover */
  border-bottom-color: #0056b3; /* Set your desired underline color on hover */
}

